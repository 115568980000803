<template>
  <section
    id="contact"
  >
    <core-jumbotron-mobile />
    <section v-if="submitted">
      <heading
        class=" mt-10 mb-5"
      >
        {{ $t("contact.submitted") }}
      </heading>
      <heading
        class=" mt-5 mb-5 title"
      >
        {{ $t("contact.intouchsoon") }}
      </heading>
    </section>
    <section v-if="!submitted">
      <heading
        v-if="!this.$route.query.quote &&!this.$route.query.product "
        class="hidden-sm-and-down mt-10 mb-5"
      >
        {{ $t("contact.question") }}
      </heading>
      <heading
        v-if="!this.$route.query.quote &&!this.$route.query.product "
        class="hidden-sm-and-down mt-5 mb-5 title"
      >
        {{ $t("contact.hear_from_you") }}
      </heading>
      <v-alert
        v-if="show_promotion"
        text
        prominent
        rounded

        color="success"
        icon="mdi-tag"
      >
        <h3 class="headline">
          {{ promotion.content[$i18n.locale].title }}
        </h3>

        <v-row
          align="center"
          no-gutters
        >
          <v-col class="grow">
            {{ promotion.content[$i18n.locale].sub_title }}
          </v-col>
        </v-row>
      </v-alert>

      <heading
        v-if="!show_promotion && (this.$route.query.quote || this.$route.query.product)"
        class="hidden-sm-and-down mt-10 mb-5"
      >
        {{ $t("contact.quote") }}
      </heading>
      <heading
        v-if="show_promotion"
        class="hidden-sm-and-down mt-10 mb-5"
      >
        {{ $t("contact.offer") }}
      </heading>
      <heading
        v-if="this.$route.query.quote || this.$route.query.product"
        class="hidden-sm-and-down mt-5 mb-5 title"
      >
        {{ $t("contact.quotetext") }}
      </heading>
    </section>
    <v-container>
      <v-row>
        <v-col
          v-if="!submitted && productObject.name"
          cols="12"
          md="10"
          offset-md="1"
        >
          <v-card
            class="mx-auto"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">
                  {{ $t("contact.sales_enquiry") }}
                </div>
                <v-list-item-title
                  class="headline mb-1"
                >
                  {{ productObject.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="{productObject}">
                  {{ productObject.category }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-img
                max-width="25%"
                class="pa-5"
                contain
                position="right"
                :src="productObject.image"
              />
            </v-list-item>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          class="mb-4 grey--text hidden-md-and-up"
        >
          {{ $t("contact.question2") }}
        </v-col>
        <v-col
          v-if="!submitted"
          cols="12"
          md="10"
          offset-md="1"
        >
          <v-form
            ref="form"
            v-model="valid"
            method="POST"
            lazy-validation
            :action="url"
            @submit="saveContactMessage"
          >
            <v-container class="pa-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="fname"
                    prepend-inner-icon="mdi-star"
                    aria-autocomplete="none"
                    required
                    :rules="textRules"
                    :label="$t('contact.first_name')"
                    outlined
                    name="FNAME"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="lname"
                    prepend-inner-icon="mdi-star"
                    required
                    aria-autocomplete="none"
                    :rules="textRules"
                    :label="$t('contact.last_name')"
                    name="LNAME"
                    outlined
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="email"
                    prepend-inner-icon="mdi-star"
                    required
                    :rules="emailRules"
                    :label="$t('contact.your_email')"
                    name="EMAIL"
                    type="email"
                    outlined
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="phone"
                    prepend-inner-icon="mdi-star"
                    :rules="textRules"
                    aria-autocomplete="none"
                    :label="$t('contact.your_phone_number')"
                    name="PHONE"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="address"
                    aria-autocomplete="none"
                    :label="$t('contact.address')"
                    name="ADDRESS[addr1]"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="address2"
                    :label="$t('contact.address2')"
                    name="ADDRESS[addr2]"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="city"
                    aria-autocomplete="none"
                    :label="$t('contact.city')"
                    name="ADDRESS[city]"
                    outlined
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="state"
                    :label="$t('contact.state')"
                    name="ADDRESS[state]"
                    outlined
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="zip"
                    :label="$t('contact.zip')"
                    name="ADDRESS[zip]"
                    outlined
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="country"
                    aria-autocomplete="none"
                    prepend-inner-icon="mdi-star"
                    :rules="textRules"
                    :label="$t('contact.country')"
                    name="ADDRESS[country]"
                    outlined
                    :items="countries"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="product"
                    :label="$t('contact.product')"
                    name="MMERGE5"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="message"
                    :label="$t('contact.message')"
                    name="MMERGE6"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-btn
                    :disabled="!valid"
                    :block="$vuetify.breakpoint.xsOnly"
                    type="submit"
                    depressed
                    x-large
                  >
                    {{ $t("contact.send_message") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>

        <v-col
          cols="10"
          md="10"
          offset-md="3"
        >
          <v-container
            class="pa-0"
            fluid
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-card-title class="grey--text">
                  {{ $t("contact.mailing_address") }}
                </v-card-title>
                <v-card-text>
                  <div>Oria Agriculture</div>
                  <div>642 Chemin de Corcelles</div>
                  <div>01140 Saint-Étienne-sur-Chalaronne</div>
                  <div>France</div>
                </v-card-text>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-card-title class="grey--text">
                  {{ $t("contact.contact_info") }}
                </v-card-title>
                <v-card-text>
                  <div>+33 (0)4 27 50 10 31</div>
                  <div>
                    <a
                      href="mailto:info@oriaagriculture.com"
                      class="blue--text"
                      @click="trackLead()"
                    >info@oriaagriculture.com</a>
                  </div>
                  <div>
                    <a
                      href="https://www.oriaagriculture.com"
                      class="blue--text"
                    >www.oriaagriculture.com</a>
                  </div>
                </v-card-text>
              </v-col>

              <v-col cols="8">
                <v-row justify="space-around">
                  <a
                    href="https://twitter.com/OriaAgriculture"
                    target="_blank"
                  ><v-icon
                    x-large
                    color="grey darken-2 "
                  >mdi-twitter</v-icon></a>

                  <a
                    href="https://www.facebook.com/oriaagriculture/"
                    target="_blank"
                  ><v-icon
                    x-large
                    color="grey darken-2"
                  >mdi-facebook</v-icon></a>

                  <a
                    href="https://www.linkedin.com/company/oriaagriculture"
                    target="_blank"
                  ><v-icon
                    x-large
                    color="grey darken-2"
                  >mdi-linkedin</v-icon></a>

                  <a
                    href="https://www.youtube.com/channel/UCLBtMTPOW33WlvKlCyggjzQ"
                    target="_blank"
                  ><v-icon
                    x-large
                    color="grey darken-2"
                  >mdi-youtube</v-icon></a>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
    >
      {{ $t("contact.errors") }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </section>
</template>

<script>

  import i18n from '../i18n'
  import moment from 'moment'

  export default {
    metaInfo: {
      title: i18n.t('menu.contact'),

    },
    components: {
      Heading: () => import('@/components/Heading'),
      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
    },
    data: function () {
      return {
        countries: [
          'Afghanistan',
          'Albania',
          'Algeria',
          'American Samoa',
          'Andorra',
          'Angola',
          'Anguilla',
          'Antarctica',
          'Antigua and Barbuda',
          'Argentina',
          'Armenia',
          'Aruba',
          'Australia',
          'Austria',
          'Azerbaijan',
          'Bahamas (the)',
          'Bahrain',
          'Bangladesh',
          'Barbados',
          'Belarus',
          'Belgium',
          'Belize',
          'Benin',
          'Bermuda',
          'Bhutan',
          'Bolivia (Plurinational State of)',
          'Bonaire, Sint Eustatius and Saba',
          'Bosnia and Herzegovina',
          'Botswana',
          'Bouvet Island',
          'Brazil',
          'British Indian Ocean Territory (the)',
          'Brunei Darussalam',
          'Bulgaria',
          'Burkina Faso',
          'Burundi',
          'Cabo Verde',
          'Cambodia',
          'Cameroon',
          'Canada',
          'Cayman Islands (the)',
          'Central African Republic (the)',
          'Chad',
          'Chile',
          'China',
          'Christmas Island',
          'Cocos (Keeling) Islands (the)',
          'Colombia',
          'Comoros (the)',
          'Congo (the Democratic Republic of the)',
          'Congo (the)',
          'Cook Islands (the)',
          'Costa Rica',
          'Croatia',
          'Cuba',
          'Curaçao',
          'Cyprus',
          'Czechia',
          "Côte d'Ivoire",
          'Denmark',
          'Djibouti',
          'Dominica',
          'Dominican Republic (the)',
          'Ecuador',
          'Egypt',
          'El Salvador',
          'Equatorial Guinea',
          'Eritrea',
          'Estonia',
          'Eswatini',
          'Ethiopia',
          'Falkland Islands (the) [Malvinas]',
          'Faroe Islands (the)',
          'Fiji',
          'Finland',
          'France',
          'French Guiana',
          'French Polynesia',
          'French Southern Territories (the)',
          'Gabon',
          'Gambia (the)',
          'Georgia',
          'Germany',
          'Ghana',
          'Gibraltar',
          'Greece',
          'Greenland',
          'Grenada',
          'Guadeloupe',
          'Guam',
          'Guatemala',
          'Guernsey',
          'Guinea',
          'Guinea-Bissau',
          'Guyana',
          'Haiti',
          'Heard Island and McDonald Islands',
          'Holy See (the)',
          'Honduras',
          'Hong Kong',
          'Hungary',
          'Iceland',
          'India',
          'Indonesia',
          'Iran (Islamic Republic of)',
          'Iraq',
          'Ireland',
          'Isle of Man',
          'Israel',
          'Italy',
          'Jamaica',
          'Japan',
          'Jersey',
          'Jordan',
          'Kazakhstan',
          'Kenya',
          'Kiribati',
          "Korea (the Democratic People's Republic of)",
          'Korea (the Republic of)',
          'Kuwait',
          'Kyrgyzstan',
          "Lao People's Democratic Republic (the)",
          'Latvia',
          'Lebanon',
          'Lesotho',
          'Liberia',
          'Libya',
          'Liechtenstein',
          'Lithuania',
          'Luxembourg',
          'Macao',
          'Madagascar',
          'Malawi',
          'Malaysia',
          'Maldives',
          'Mali',
          'Malta',
          'Marshall Islands (the)',
          'Martinique',
          'Mauritania',
          'Mauritius',
          'Mayotte',
          'Mexico',
          'Micronesia (Federated States of)',
          'Moldova (the Republic of)',
          'Monaco',
          'Mongolia',
          'Montenegro',
          'Montserrat',
          'Morocco',
          'Mozambique',
          'Myanmar',
          'Namibia',
          'Nauru',
          'Nepal',
          'Netherlands (the)',
          'New Caledonia',
          'New Zealand',
          'Nicaragua',
          'Niger (the)',
          'Nigeria',
          'Niue',
          'Norfolk Island',
          'Northern Mariana Islands (the)',
          'Norway',
          'Oman',
          'Pakistan',
          'Palau',
          'Palestine, State of',
          'Panama',
          'Papua New Guinea',
          'Paraguay',
          'Peru',
          'Philippines (the)',
          'Pitcairn',
          'Poland',
          'Portugal',
          'Puerto Rico',
          'Qatar',
          'Republic of North Macedonia',
          'Romania',
          'Russian Federation (the)',
          'Rwanda',
          'Réunion',
          'Saint Barthélemy',
          'Saint Helena, Ascension and Tristan da Cunha',
          'Saint Kitts and Nevis',
          'Saint Lucia',
          'Saint Martin (French part)',
          'Saint Pierre and Miquelon',
          'Saint Vincent and the Grenadines',
          'Samoa',
          'San Marino',
          'Sao Tome and Principe',
          'Saudi Arabia',
          'Senegal',
          'Serbia',
          'Seychelles',
          'Sierra Leone',
          'Singapore',
          'Sint Maarten (Dutch part)',
          'Slovakia',
          'Slovenia',
          'Solomon Islands',
          'Somalia',
          'South Africa',
          'South Georgia and the South Sandwich Islands',
          'South Sudan',
          'Spain',
          'Sri Lanka',
          'Sudan (the)',
          'Suriname',
          'Svalbard and Jan Mayen',
          'Sweden',
          'Switzerland',
          'Syrian Arab Republic',
          'Taiwan (Province of China)',
          'Tajikistan',
          'Tanzania, United Republic of',
          'Thailand',
          'Timor-Leste',
          'Togo',
          'Tokelau',
          'Tonga',
          'Trinidad and Tobago',
          'Tunisia',
          'Turkey',
          'Turkmenistan',
          'Turks and Caicos Islands (the)',
          'Tuvalu',
          'Uganda',
          'Ukraine',
          'United Arab Emirates (the)',
          'United Kingdom of Great Britain and Northern Ireland (the)',
          'United States Minor Outlying Islands (the)',
          'United States of America (the)',
          'Uruguay',
          'Uzbekistan',
          'Vanuatu',
          'Venezuela (Bolivarian Republic of)',
          'Viet Nam',
          'Virgin Islands (British)',
          'Virgin Islands (U.S.)',
          'Wallis and Futuna',
          'Western Sahara',
          'Yemen',
          'Zambia',
          'Zimbabwe',
          'Åland Islands',
        ],
        valid: false,
        snackbar: false,
        textRules: [
          v => !!v || 'This field is required',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        errors: [],
        fname: null,
        lname: null,
        email: null,
        phone: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country: null,
        product: null,
        productObject: {},
        message: null,
        submitted: false,
        promotion: null,
        show_promotion: false,
        url: 'https://oriaagriculture.us20.list-manage.com/subscribe/post?u=a9ceac99e5313e211b85a883a&id=443cd8daff',
      }
    },
    created () {
      if (this.$route.query.product) {
        const parts = this.$route.query.product.split(',')
        if (parts.length === 2) {
          // fetch product info
          fetch('/api/' + i18n.locale + '/product/' + parts[0] + '/' + parts[1] + '.json')
            .then(r => r.json())
            .then(json => {
                    this.productObject = json
                    this.product = json.name + ' ' + '(https://www.oriaagriculture.com/fr/products/' + parts[0] + '/' + parts[1] + ')'
                  },
                  response => {
                    console.log('Error loading json:', response)
                  })
        }

        this.promotion = null
        const promotionsRef = this.$firebaseDatabase.collection('promotions')
        const _this = this
        promotionsRef.where('to', '>', moment().unix()).where('product', '==', parts[0] + '/' + parts[1]).orderBy('to', 'desc').limit(1).get().then(function (querySnapshot) {
          if (querySnapshot.size === 1) {
            console.log(_this.promotion = querySnapshot.docs[0].data())
            _this.promotion = querySnapshot.docs[0].data()
            if (_this.promotion.from < (new Date()).getTime()) {
              _this.show_promotion = true
            }
          }
        })
      }
    },
    methods: {

      trackLead: function () {
        this.$firebaseAnalytics.logEvent('generate_lead', { debug_mode: true })
        window.fbq('track', 'Lead')
        window.gtag('event', 'generate_lead')
      },
      addMessage: function () {
        const messagesRef = this.$firebaseDatabase.collection('messages')
        messagesRef.add(
          {
            promotion: (this.show_promotion ? this.promotion.content.en.title : 'no'),
            first_name: this.fname,
            last_name: this.lname,
            email: this.email,
            phone: this.phone,
            address: { addr1: this.address, addr2: this.address2, city: this.city, zip: this.zip, country: this.country },
            product: this.product,
            message: this.message,
            lang: i18n.locale,
            time: new Date(),
          },
        )
        this.trackLead()
        this.fname = ''
        this.lname = ''
        this.email = ''
        this.phone = ''
        this.address = ''
        this.address2 = ''
        this.city = ''
        this.zip = ''
        this.country = ''
        this.product = ''
        this.message = ''
        this.submitted = true
        this.snackbar = false
      },
      saveContactMessage (e) {
        e.preventDefault()
        if (this.$refs.form.validate()) {
          this.addMessage()
        } else {
          this.snackbar = true
        }

        /*
        const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
        fetch(proxyUrl + this.url, {
          method: 'POST',
          headers: new Headers(),
          body: JSON.stringify({
            FNAME: this.fname,
            LNAME: this.lname,
            EMAIL: this.email,
            PHONE: this.phone,
            ADDRESS: { addr1: this.address, addr2: this.address2, city: this.city, zip: this.zip, country: this.country },
            MMERGE5: this.product,
            MMERGE6: this.message,
          }),
        }).then((res) => res.json())
          .then((data) => console.log(data))
          .catch((err) => console.log(err))
        */
      },
    },
  }
</script>

<style lang="sass">

   a, v-icon
      color: white
      text-decoration: none

      &:hover
        color: grey
</style>
